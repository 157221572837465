@font-face {
  font-family: Beaufort;
  src: url("fonts/beaufortforlol-regular.otf");
}
@font-face {
  font-family: Beaufort;
  src: url("fonts/beaufortforlol-bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: Beaufort;
  src: url("fonts/beaufortforlol-regular.otf");
  font-weight: 500;
}

body {
  background: rgb(9, 40, 54);
  background: radial-gradient(
    circle,
    rgba(9, 40, 54, 1) 0%,
    rgba(4, 16, 25, 1) 57%,
    rgba(1, 10, 19, 1) 100%
  );
}

.simplebar-scrollbar::before {
  background-color: #121f47 !important;
}

.font-beaufort {
  font-family: Beaufort;
}

.font-beaufort-bold {
  font-family: Beaufort;
  font-weight: bold;
}

.font-beaufort-regular {
  font-family: Beaufort;
  font-weight: 500;
}

.lane-container {
  transition: box-shadow 0.2s cubic-bezier(0.77, 0, 0.175, 1);
}

.lane-container:hover {
  box-shadow: 0 0 20px #ecc94b, 0 0 30px #ecc94b81, 0 0 40px #ecc94b57,
    0 0 60px #ecc94b25;
}

#champion-name-showcase {
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 20%;
  pointer-events: none;
  user-select: none;
}

/* Text animations */
.tracking-in-expand {
  animation-name: focus-in-expand, blur-out-contract;
  animation-duration: 0.5s, 1s;
  animation-delay: 0s, 1s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1),
    cubic-bezier(0.55, 0.085, 0.68, 0.53);
  animation-fill-mode: both;
}

@keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes blur-out-contract {
  0% {
    -webkit-filter: blur(0.01);
    filter: blur(0.01);
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px) opacity(0%);
    filter: blur(12px) opacity(0%);
  }
}

/* Animations */

.transition-width > td {
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
}

.center-bg-image {
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.animation-bg-intro {
  animation-name: transitionFromBright, fadeIn, slideInRight;
  animation-duration: 0.9s, 0.2s, 0.2s;
  animation-timing-function: cubic-bezier(0, 0, 0, 1), linear,
    cubic-bezier(1, 0, 1, 1);
  animation-fill-mode: forwards;
}

.animation-bg-left {
  animation-name: transitionFromBright, fadeOut0to1, slideLeft,
    transitionToBright, fadeOut;
  animation-duration: 0.9s, 0.2s, 0.2s, 0.2s, 0.2s;
  animation-delay: 0s, 1.5s, 1.5s, 1.5s, 1.5s;
  animation-timing-function: cubic-bezier(0, 0, 0, 1), linear,
    cubic-bezier(1, 0, 1, 1), linear, linear;
  animation-fill-mode: forwards;
}

.animation-bg-right {
  animation-name: transitionFromBright, fadeOut0to1, slideRight,
    transitionToBright, fadeOut;
  animation-duration: 0.9s, 0.2s, 0.2s, 0.2s, 0.2s;
  animation-delay: 0s, 1.5s, 1.5s, 1.5s, 1.5s;
  animation-timing-function: cubic-bezier(0, 0, 0, 1), linear,
    cubic-bezier(1, 0, 1, 1), linear, linear;
  animation-fill-mode: forwards;
}

@keyframes transitionFromBright {
  0% {
    transform: scale(1.2);
    filter: brightness(1.5) blur(8px);
  }

  100% {
    transform: scale(1);
    filter: brightness(1) blur(0px);
  }
}

@keyframes fadeOut0to1 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-300px);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(300px);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(300px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes transitionToBright {
  0% {
    filter: brightness(1) blur(0px);
  }

  100% {
    filter: brightness(1.5) blur(8px);
  }
}

/* Champion thumb animations */
.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.22, 1, 0.36, 1) both;
  animation: slide-in-left 0.5s cubic-bezier(0.22, 1, 0.36, 1) both;
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.22, 1, 0.36, 1) both;
  animation: slide-in-right 0.5s cubic-bezier(0.22, 1, 0.36, 1) both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* Grid slide animations */
.delay-animation {
  animation-delay: 1s;
}

.slide-in-bottom {
  /* -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.86, 0, 0.07, 1) both; */
  /* animation: slide-in-bottom 0.5s cubic-bezier(0.86, 0, 0.07, 1) both; */
  animation-name: slide-in-bottom;
  -webkit-animation-name: slide-in-bottom;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.slide-out-bottom {
  -webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.86, 0, 0.07, 1) both;
  animation: slide-out-bottom 0.5s cubic-bezier(0.86, 0, 0.07, 1) both;
}
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}

/* Glow animation */
.glow-left {
  font-size: 80px;
  color: #fff;
  text-align: center;
  animation: glow-left 1s ease-in-out infinite alternate;
  position: absolute;
  top: 40%;
  left: 35%;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.glow-right {
  font-size: 80px;
  color: #fff;
  text-align: center;
  animation: glow-right 1s ease-in-out infinite alternate;
  position: absolute;
  top: 40%;
  right: 35%;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
@keyframes glow-left {
  from {
    filter: drop-shadow(0 0 10px #fff);
    transform: translateX(0);
  }

  to {
    filter: drop-shadow(0 0 30px rgb(233, 255, 110));
    transform: translateX(-30px);
  }
}

@keyframes glow-right {
  from {
    filter: drop-shadow(0 0 10px #fff);
    transform: translateX(0);
  }

  to {
    filter: drop-shadow(0 0 30px rgb(233, 255, 110));
    transform: translateX(30px);
  }
}
