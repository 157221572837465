@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter-VariableFont_slnt\,wght.ttf');
}

@font-face {
    font-family: 'WorkSans';
    src: url('../assets/fonts/WorkSans-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'WorkSans';
    src: url('../assets/fonts/WorkSans-Italic-VariableFont_wght.ttf');
    font-style: italic;
}